<template>
  <div class="row">
    <div class="col">
      <b-card>
        <h2>Sample Test Question</h2>
        <hr />
        <div class="text-right">
          <button class="btn btn-sm btn-outline-primary" @click="$router.push({name:'sampletest-result', params:{book_id}})" title="Finish the test">Finish</button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SampleTestQuestion',

  data() {
    return {
      book_id: this.$route.params.book_id,
      questionNo: +(this.$route.params.question_no || 1),
    };
  },

};
</script>
